<template>
  <div
    id="video-app"
    class="bg-video-app"
  >
    <div class="d-flex align-center justify-center logo-video-app">
      <v-img
        :src="appLogo"
        max-height="40px"
        max-width="40px"
        alt="logo"
        contain
        class="me-3 "
      ></v-img>
      <span
        class="font-weight-bold text-lg"
        style="color: white !important; font-family: 'Orbitron';"
      >
        {{ appName }}
      </span>
    </div>
    <div class="header-video-app d-flex justify-center">
      <div class="d-flex align-center">
        <v-avatar
          :color="videoData.share_user_avatar ? '' : 'secondary'"
          :class="videoData.share_user_avatar ? 'mr-3' : 'v-avatar-light-bg primary--text mr-3'"
          size="32"
        >
          <v-img
            :src="videoData.share_user_avatar ? videoData.share_user_avatar : require(`@/assets/images/avatars/user-hp.png`)"
          ></v-img>
        </v-avatar>
        <v-btn
          class="text-sm pa-0 ma-0"
          style="text-transform: inherit;"
          text
          @click="downloadApp"
        >
          <span class="white--text text-sm">
            {{ videoData.share_user }} {{ t('videos.using') }} <strong>{{ t('videos.get_app') }}</strong>
          </span>
        </v-btn>
      </div>
    </div>
    <div
      class="auth-wrapper auth-v1"
    >
      <div
        class="auth-inner"
        style="width:50rem;"
      >
        <v-row class="d-flex justify-center mb-16">
          <v-col
            cols="12"
            class="align-self-start pa-0"
          >
            <v-card
              color="#000000"
              class="rounded-lg"
              elevation="15"
            >
              <v-card-text class="pa-3">
                <div>
                  <div
                    class="ms-0 width-full"
                  >
                    <v-row class="pa-1">
                      <v-col
                        cols="12"
                        class="mb-1 pa-1 d-flex justify-space-between align-center"
                      >
                        <div class="d-flex align-center">
                          <v-avatar
                            :color="videoData.share_user_avatar ? '' : 'secondary'"
                            :class="videoData.share_user_avatar ? 'mr-3' : 'v-avatar-light-bg primary--text mr-3'"
                            size="40"
                          >
                            <v-img
                              v-if="videoData.share_user_avatar"
                              :src="videoData.share_user_avatar"
                            ></v-img>
                            <span
                              v-else
                              class="
                              font-weight-medium"
                            >
                              {{ videoData.share_user }}
                            </span>
                          </v-avatar>
                          <v-col>
                            <v-row>
                              <span class="text-sm font-weight-bold white--text">{{ videoData.share_user }}</span>
                            </v-row>
                            <v-row>
                              <span class="text-xs">{{ videoData.facility_name }}</span>
                            </v-row>
                          </v-col>
                        </div>
                        <div>
                          <span class="text-sm">{{ videoData.video_type === 'H' ? videoData.video_date : videoData.video_time }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-card-text>
              <video-player
                v-if="videoData.video_type === 'H'"
                id="refVideoPlayer"
                ref="refVideoPlayer"
                class="video-player-box"
                :options="playerOptions"
                :playsinline="true"
                custom-event-name="customstatechangedeventname"
              ></video-player>
              <!--
                @timeupdate="onPlayerTimeupdate($event)"
                @canplaythrough="onPlayerCanplaythrough($event)"
                @ready="playerReadied"
                @loadeddata="onPlayerLoadeddata($event)"
                @loadedmetadata="onPlayerLoadedmetadata($event)"
              -->
              <v-img
                v-else
                class="img-h"
                width="100%"
                :src="videoData.facility_logo"
              ></v-img>

              <v-card-actions
                v-if="videoData.video_type !== 'V'"
                class="dense mt-1"
              >
                <v-row class="pt-1">
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between  pl-1"
                  >
                    <div class="d-flex align-center">
                      <v-icon
                        class="mr-2"
                        size="18"
                      >
                        {{ icons.mdiStar }}
                      </v-icon>
                      <span class="text-xs">{{ videoData.highlight_name }}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { ref, computed, onMounted } from '@vue/composition-api'
import { useRouter, downloadAppByOS } from '@core/utils'
import 'video.js/dist/video-js.css'
import { mdiPlus, mdiRefresh, mdiLock, mdiDotsVertical, mdiStar } from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import { getVideoPlaybackType, logUserActivity } from '@api'
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import { loadLanguageAsync } from '@/plugins/i18n'

export default {
  setup() {
    const videoData = ref({
      end_playback_time: null,
      facility_logo: null,
      facility_name: null,
      field_name: null,
      highlight_name: null,
      highlight_owner: null,
      highlight_owner_avatar: null,
      share_user: null,
      share_user_avatar: null,
      start_playback_time: null,
      thumbnails: null,
      video_date: null,
      video_id: null,
      video_link: null,
      video_long: null,
      video_thumbnail: null,
      video_time: null,
      video_type: null,
    })
    const refUsername = ref(null)
    const refVideo = ref(null)
    const players = ref({})
    const logo = ref(require('@/assets/images/icons/logo.png'))
    const source = ref('https://static.smartisanos.cn/common/video/smartisan-tnt-jianguo.mp4')
    const initdone = ref(false)
    const cover = ref(
      'https://static.smartisanos.cn/pr/assets/images/smartisan-tnt-jianguo.190162f215c4f5f2aaabf38e5782b3af.jpg',
    )

    const playerOptions = ref({
      controls: false,
      responsive: true,
      breakpoints: true,
      fill: true,
      autoplay: true,
      muted: true,
      loop: true,
      language: 'en',
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      sources: [],
      poster: 'https://fanaty-api-stage.s3.amazonaws.com/no-thumb.jpg',
      notSupportedMessage: 'Este video no está disponible temporalmente, intente nuevamente más tarde.',
      usingPlugin: true,
      fluid: true,
      aspectRatio: '16:9',
    })
    const USER_ACT_VIEW_HIGHLIGHT_WEB = 'Open Highlight Web'
    const { t } = useUtils()
    const { route, router } = useRouter()
    const refVideoPlayer = ref(null)
    const player = computed(() => refVideoPlayer.value.player)

    const onPlayerTimeupdate = auxPlayer => {
      if (
        // eslint-disable-next-line operator-linebreak
        videoData.value &&
        // eslint-disable-next-line operator-linebreak
        (auxPlayer.currentTime() >= videoData.value.end_playback_time ||
          auxPlayer.currentTime() < videoData.value.start_playback_time)
      ) {
        initdone.value = false
        auxPlayer.currentTime(videoData.value.start_playback_time)
      }
    }
    const onPlayerCanplaythrough = auxPlayer => {
      if (!initdone.value && videoData.value && videoData.value.video_type === 'H') {
        auxPlayer.currentTime(videoData.value.start_playback_time)
        initdone.value = true
      }
    }

    const onPlayerCanplay = auxPlayer => {
      auxPlayer.currentTime(videoData.value.start_playback_time)
    }

    const playerReadied = auxPlayer => {
      if (!initdone.value && videoData.value && videoData.value.video_type === 'H') {
        auxPlayer.currentTime(videoData.value.start_playback_time)
        initdone.value = true
      }
    }

    const onPlayerLoadeddata = auxPlayer => {
      auxPlayer.currentTime(videoData.value.start_playback_time)
    }

    const onPlayerLoadedmetadata = auxPlayer => {
      if (!initdone.value && videoData.value && videoData.value.video_type === 'H') {
        auxPlayer.currentTime(videoData.value.start_playback_time)
        initdone.value = true
      }
    }

    onMounted(async () => {
      // loadLanguageAsync(navigator.language.includes('en') ? 'en' : 'es' || 'en')
      loadLanguageAsync('en')
      refVideo.value = route.value.params.playback
      refUsername.value = route.value.params.username

      const response = await getVideoPlaybackType(refVideo.value, 'H', refUsername.value)
      if (response.ok) {
        videoData.value = response.data

        playerOptions.value.sources.push({
          type: 'video/mp4',
          src: videoData.value.video_link,
        })

        logUserActivity({
          user_id: videoData.value.highlight_owner,
          video_id: null,
          highlight_id: videoData.value.highlight_id,
          action: USER_ACT_VIEW_HIGHLIGHT_WEB,
        })
      } else {
        router.push({
          path: '/video-not-found',
        })
      }
    })

    const downloadApp = () => {
      // if (isAndroid()) window.open('https://play.google.com/store/apps/details?id=com.fanaty', '_blank')
      // if (isIOS()) window.open('https://apps.apple.com/us/app/fanaty/id1461051554', '_blank')
      // if (getOS() === 'Other') window.open('https://fanaty.com/download', '_blank')
      downloadAppByOS(
        'https://play.google.com/store/apps/details?id=com.fanaty',
        'https://apps.apple.com/us/app/fanaty/id1461051554',
        'https://fanaty.com/download',
      )
    }

    return {
      videoData,
      refUsername,
      refVideo,
      players,
      logo,
      initdone,
      source,
      cover,
      USER_ACT_VIEW_HIGHLIGHT_WEB,

      onPlayerTimeupdate,
      onPlayerCanplaythrough,
      onPlayerCanplay,
      onPlayerLoadeddata,
      onPlayerLoadedmetadata,
      playerReadied,
      player,
      playerOptions,
      downloadApp,
      avatarText,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // i18n
      t,

      icons: {
        mdiPlus,
        mdiRefresh,
        mdiLock,
        mdiDotsVertical,
        mdiStar,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
<style scope>
.video-player-box .video-js,
.img-h {
  width: 100% !important;
  height: 56vh !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .video-player-box .video-js,
  .img-h {
    width: 100% !important;
    height: 26vh !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .video-player-box .video-js,
  .img-h {
    width: 100% !important;
    height: 26vh !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .video-player-box .video-js,
  .img-h {
    width: 100% !important;
    height: 50vh !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .video-player-box .video-js,
  .img-h {
    width: 100% !important;
    height: 64vh !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .video-player-box .video-js,
  .img-h {
    width: 100% !important;
    height: 64vh !important;
  }
}

#video-app {
  font-family: 'Open Sans' !important;
}

.bg-video-app {
  background-color: black;
}

.header-video-app {
  background-color: #ff3232;
  min-height: 6vh;
}

.logo-video-app {
  background-color: black;
  min-height: 10vh;
}

.btn-video-app {
  min-height: 12vh;
}

.auth-wrapper {
  min-height: calc(var(--vh, 1vh) * 84);
}

.img-h .v-responsive__content {
  width: 100% !important;
}

.img-h .v-responsive__sizer {
  padding: 0 !important;
}
</style>
